<p-toast></p-toast>
<div class='card'>
  <div class='card'>
    <div class='grid align-items-baseline'>
      <div class='col'>
        <h5>Schadensdetails</h5>
      </div>
      <div class='col'>
        <div class='float-end flex gap-2'>
					<p-chip title='Mobile' styleClass='custom-chip-blue' *ngIf='incident.isMobile'>
						<i class='pi pi-tablet mr-1'></i> Mobile
					</p-chip>
          <p-chip (click)='linkAutoclaimDialog(incident)' title='Mit Autoclaim verknüpfen' styleClass='custom-chip-red'
                  *ngIf='!isAcConnectedToIncident && !autoClaimLoad'><i
            class='pi pi-link mr-1'></i> {{getLabelForChip(incident)}}
          </p-chip>
          <p-chip title='wird mit Autoclaim verknüpft' styleClass='custom-chip-yellow' *ngIf='autoClaimLoad'>
            <i class='pi pi-spin pi-spinner mr-1'></i> Wird verknüpft
          </p-chip>
          <p-chip title='Mit Autoclaim verknüpft' styleClass='custom-chip-green' *ngIf='isAcConnectedToIncident'>
            <i class='pi pi-check-circle mr-1'></i>{{incident.autoclaimReferenceNo}}
          </p-chip>
        </div>
      </div>
    </div>
    <form [formGroup]='incidentDetailViewForm'>
      <div class='grid p-fluid mt-4'>
        <div class='col-12 mt-4'>
          <p><i class='pi pi-calendar-plus'></i> Erstellungsdatum: <b>{{incident.createdOn | date: 'dd.MM.yyyy'}}</b>
          </p>
        </div>
        <!-- Vornamen(n) -->
        <div class='col-6 mt-4'>
				<span class='p-float-label p-input-icon-left'>
					<i class='pi pi-user'></i>
				<input formControlName='cDotOneDotTwo' type='text' pInputText
               [value]="incident.vehicleRegistration ? incident.vehicleRegistration.cDotOneDotTwo : ''">
					<label>Vorname(n)</label>
					</span>
        </div>
        <!-- Name oder Firmenname -->
        <div class='col-6 mt-4'>
				<span class='p-float-label p-input-icon-left'>
					<i class='pi pi-user'></i>
				<input formControlName='cDotOneDotOne' type='text' pInputText
               [value]="incident.vehicleRegistration ? incident.vehicleRegistration.cDotOneDotOne : ''">
					<label>Name oder Firmenname</label>
					</span>
        </div>
        <!-- Fahrzeughalter Anschrift -->
        <div class='col-6 mt-4'>
				<span class='p-float-label p-input-icon-left'>
					<i class='pi pi-map-marker'></i>
				<input formControlName='cDotOneDotThree' type='text' pInputText
               [value]="incident.vehicleRegistration ? incident.vehicleRegistration.cDotOneDotThree : ''">
					<label>Fahrzeughalter Anschrift</label>
					</span>
        </div>
        <!-- Kennzeichen -->
        <div class='col-6 mt-4'>
				<span class='p-float-label p-input-icon-left'>
					<i class='pi pi-car'></i>
				<input formControlName='a' type='text' pInputText
               [value]="incident.vehicleRegistration ? incident.vehicleRegistration.a : ''">
				<label>Kennzeichen</label>
				</span>
        </div>
        <!-- Fahrgestellnummer -->
        <div class='col-6 mt-4'>
				<span class='p-float-label p-input-icon-left'>
					<i class='pi pi-map'></i>
				<input formControlName='e' type='text' pInputText
               [value]="incident.vehicleRegistration ? incident.vehicleRegistration.e : ''">
				<label>Fahrgestellnummer</label>
				</span>
        </div>
        <!-- Registrierungsdatum -->
        <div class='col-6 mt-4'>
				<span class='p-float-label p-input-icon-left'>
					<i class='pi pi-calendar'></i>
				<input formControlName='b' type='text' pInputText
               [value]="incident.vehicleRegistration ? incident.vehicleRegistration.b : ''">
				<label>Erstzulassung</label>
				</span>
        </div>
        <!-- HSN -->
        <div class='col-6 mt-4'>
				<span class='p-float-label p-input-icon-left'>
					<i class='pi pi-map'></i>
				<input formControlName='twoDotOne' type='text' pInputText
               [value]="incident.vehicleRegistration ? incident.vehicleRegistration.twoDotOne : ''">
					<label>HSN</label>
					</span>
        </div>
        <!-- TSN -->
        <div class='col-6 mt-4'>
				<span class='p-float-label p-input-icon-left'>
					<i class='pi pi-map'></i>
				<input formControlName='twoDotTwo' type='text' pInputText
               [value]="incident.vehicleRegistration ? incident.vehicleRegistration.twoDotTwo : ''">
					<label>TSN</label>
					</span>
        </div>
        <!-- Schadensart -->
        <div *ngIf='incidentDetailViewForm.disabled' class='col-6 mt-4'>
				<span class='p-float-label p-input-icon-left'>
					<i class='pi pi-car'></i>
				<input formControlName='incidentType' type='text' pInputText
               [value]='getEnumName(incident.incidentType)'>
				<label>Schadensart</label>

				</span>
        </div>
        <span *ngIf='incidentDetailViewForm.enabled'>
                <div class='col-12 mt-5'>
                    <h6>Schadensart</h6>
                </div>
                <div class='field-radiobutton mt-3'>
                    <p-radioButton formControlName='incidentType' id='liability'
                                   [value]='incidentTypes.liability'
                                   inputId='liability'></p-radioButton>
                    <label for='liability'>Haftpflicht</label>
									<p-radioButton formControlName='incidentType' class='ml-5' id='partiallyComprehensive'
                                 [value]='incidentTypes.partiallyComprehensive'
                                 inputId='partiallyComprehensive'></p-radioButton>
                    <label for='fullyComprehensive'>Teilkasko</label>
                    <p-radioButton formControlName='incidentType' class='ml-5' id='fullyComprehensive'
                                   [value]='incidentTypes.fullyComprehensive'
                                   inputId='fullyComprehensive'></p-radioButton>
                    <label for='fullyComprehensive'>Vollkasko</label>
                  <!-- "wait for customer response (AVP)"
                    <p-radioButton formControlName='incidentType' class='ml-5' id='selfpayer'
                                   [value]='incidentTypes.selfpayer'
                                   inputId='selfpayer'></p-radioButton>
                    <label for='selfpayer'>Selbstzahler</label>
                    <p-radioButton formControlName='incidentType' class='ml-5' id='none'
                                   [value]='incidentTypes.none'
                                   inputId='none'></p-radioButton>
                    <label for='none'>Nicht bekannt</label>
                    -->
                </div>
</span>

        <div class='col-12 mt-5'>
          <h6>Autoclaim Verknüpfung</h6>
        </div>
        <div class='col-6 mt-4'>
					<span class='p-float-label p-input-icon-left' *ngIf='incidentDetailViewForm.enabled'>
					<p-calendar dateFormat='dd.mm.yy' class='custom-calendar' formControlName='autoclaimAccidentDateTime'></p-calendar>
						<label>Unfalldatum</label>
						</span>
          <span class='p-float-label p-input-icon-left' *ngIf='incidentDetailViewForm.disabled'>
					<i class='pi pi pi-calendar'></i>
				<input formControlName='autoclaimAccidentDateTime'
               type='text' pInputText
               [value]="incident.accidentDateTime | date: 'dd.MM.yyyy'">
				<label>Unfalldatum</label>
				</span>
        </div>
        <div class='col-6 mt-4'>
				<span class='p-float-label p-input-icon-left'>
					<i class='pi pi pi-map-marker'></i>
				<input formControlName='autoclaimAccidentPlace' type='text' pInputText
               [value]="incident ? incident.accidentPlace : ''">
				<label>Unfallort</label>
				</span>
        </div>

        <div class='col-12 mt-5'>
          <h6>Zusätzliche Informationen</h6>
        </div>
        <!-- Telefonnummer -->
        <div class='col-6 mt-4'>
				<span class='p-float-label p-input-icon-left'>
					<i class='pi pi pi-phone'></i>
				<input formControlName='phoneNumber' type='text' pInputText
               [value]="incident ? incident.phone : ''">
				<label>Telefon</label>
				</span>
        </div>
        <!-- E-Mail Adresse -->
        <div class='col-6 mt-4'>
				<span class='p-float-label p-input-icon-left'>
					<i class='pi pi-envelope'></i>
				<input formControlName='email' type='text' pInputText
               [value]="incident ? incident.email : ''">
				<label>E-Mail Adresse</label>
				</span>
        </div>
        <div class='col-12 mt-5'>
          <h6>Abweichender Fahrer</h6>
        </div>
        <!-- Fahrer Vorname -->
        <div class='col-6 mt-4'>
				<span class='p-float-label p-input-icon-left'>
					<i class='pi pi-id-card'></i>
				<input formControlName='additionalDriverFirstName' type='text'
               pInputText
               [value]="incident.driver ? incident.driver.firstName : ''">
				<label>Vorname(n)</label>
				</span>
        </div>
        <!-- Fahrer Nachname -->
        <div class='col-6 mt-4'>
				<span class='p-float-label p-input-icon-left'>
					<i class='pi pi-id-card'></i>
				<input formControlName='additionalDriverLastName' type='text'
               pInputText
               [value]="incident.driver ? incident.driver.lastName : ''">
				<label>Nachname</label>
				</span>
        </div>
        <!-- Fahrer Straße -->
        <div class='col-6 mt-4'>
				<span class='p-float-label p-input-icon-left'>
					<i class='pi pi-map-marker'></i>
				<input formControlName='additionalDriverStreet' type='text' pInputText
               [value]="incident.driver ? incident.driver.street : ''">
				<label>Straße</label>
				</span>
        </div>
        <!-- Fahrer Hausnummer -->
        <div class='col-6 mt-4'>
				<span class='p-float-label p-input-icon-left'>
					<i class='pi pi-map-marker'></i>
				<input formControlName='additionalDriverNumber' type='text' pInputText
               [value]="incident.driver ? incident.driver.houseNo : ''">
				<label>Nummer</label>
				</span>
        </div>
        <!-- Fahrer Zib -->
        <div class='col-6 mt-4'>
				<span class='p-float-label p-input-icon-left'>
					<i class='pi pi-map-marker'></i>
				<input formControlName='additionalDriverZip' type='text' pInputText
               [value]="incident.driver ? incident.driver.postalCode : ''">
				<label>Postleitzahl</label>
				</span>
        </div>
        <!-- Fahrer Place -->
        <div class='col-6 mt-4'>
				<span class='p-float-label p-input-icon-left'>
					<i class='pi pi-map-marker'></i>
				<input formControlName='additionalDriverPlace' type='text' pInputText
               [value]="incident.driver ? incident.driver.city : ''">
				<label>Ort</label>
				</span>
        </div>
      </div>
    </form>
    <div class='mt-3 grid'>
      <div class='col'>
      </div>
      <div class='float-end'>
        <button *ngIf='incidentDetailViewForm.enabled' pButton class='p-button-danger mr-2'
                label='Kalkulation löschen'
                icon='pi pi-trash'
                (click)='deleteIncidentConfirmDialog(incident)'></button>
        <button *ngIf='incidentDetailViewForm.enabled' pButton (click)='cancelEditIncidentButton()'
                class='p-button-danger mr-2'
                label='Abbrechen'
                icon='pi pi-times'></button>
        <button pButton (click)='updateIncident()' *ngIf='incidentDetailViewForm.enabled' class='mr-2'
                label='Änderung speichern'
                icon='pi pi-save'></button>
        <span *ngIf='asmVisible()'>
            <button pButton *ngIf='incidentDetailViewForm.disabled' (click)='editIncidentButton(incident)'
                    class='mr-2'
                    label='Kalkulation bearbeiten'
                    icon='pi pi-pencil'></button>
                </span>
				<span *ngIf='incidentDetailViewForm.disabled'>
					<button (click)='downloadCombiPlusXML(incidentId)' pButton class='mr-2' label='CombiPlus' icon='pi pi-download'></button>
				</span>
        <span *ngIf='userVisible()'>
            <button pButton *ngIf='incidentDetailViewForm.disabled' (click)='restartCalculationDetailView()'
                    class='mr-2'
                    label='Kalkulation erneut starten'
                    icon='pi pi-refresh'></button>
                </span>
      </div>
    </div>
  </div>
  <div class='card'>
    <div class='grid'>
      <div class='col'></div>
      <div class='col'>
        <button class='float-end' (click)='syncToAutoclaim(incidentId)' icon='pi pi-sync' pButton
                label='Synchronisieren'></button>
        <button pButton (click)='openAddDocumentPopup()'
                class='float-end mr-2'
                label='Datei hochladen'
                icon='pi pi-upload'></button>
      </div>
    </div>
    <p-tabView>
      <p-tabPanel header='Dokumente'>
        <p-dataView [loading]='loading' emptyMessage='Keine Einträge vorhanden' #dv [value]='binaries' filterBy='name'
                    [lazy]='true' [sortField]='sortField' [sortOrder]='sortOrder' layout='grid'>
          <ng-template pTemplate='header'>
            <div>
              <p-dropdown [options]='sortOptions' placeholder='Nach Datum sortieren'
                          (onChange)='onSortChange($event)' styleClass='p-mb-2 p-mb-md-0'></p-dropdown>
            </div>
          </ng-template>
          <ng-template let-binary pTemplate='gridItem'>
            <div class='p-col-12 m-5'>
              <div>
                <div class='mt-3'>
                  <div class='mb-4'>
										<span *ngIf='loading'>
											<p-progressBar [style]="{'height': '6px'}" mode='indeterminate'></p-progressBar>
										</span>
                    <div class='container mb-4'>
                      <img class='custom-img' [hidden]='loading'
                           [src]=binary.preSignedS3UriThumbnail [alt]='binary.originalFilename'
                           style='cursor: pointer; width: 100%; height: 100%;'
                           (error)='handleMissingImage($event)' />
                      <div class='img-button centered'>
                        <button title='Bild in Galerie anzeigen' (click)='showGalleria(binary.preSignedS3Uri)'
                                class='custom-tn-button mr-3'
                                pButton
                                icon='pi pi-search-plus'></button>
                        <button title='Bild herunterladen'
                                (click)='downloadImage(binary.preSignedS3Uri, binary.originalFilename)'
                                class='custom-tn-button mr-3'
                                pButton
                                icon='pi pi-cloud-download'></button>
                        <button title='Bild löschen' (click)='deleteBinaryConfirmDialog(binary)'
                                class='custom-tn-button p-button-danger'
                                pButton
                                icon='pi pi-trash'></button>
                      </div>
                      <button tooltip='Dieses Bild wurde zu Autoclaim übertragen' pButton
                              *ngIf='getAcBinarySyncState(binary)' icon='pi pi-check-circle'
                              class='top-right p-button-rounded'></button>
                      <button tooltip='Dieses Bild konnte nicht zu Autoclaim übertragen werden' pButton
                              *ngIf='!getAcBinarySyncState(binary)' icon='pi pi-exclamation-triangle'
                              class='sync-warn-button p-button-rounded p-button-warning'></button>
                      <p-chip styleClass='custom-chip-green-createdOn'
                              class='bottom-right'>
                        <b>Erstellungsdatum: {{binary.createdOn | date: 'dd.MM.yyyy'}}</b>
                      </p-chip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate='emptymessage'>
            <tr>
              <td [attr.colspan]='9'>
                Keine Einträge vorhanden
              </td>
            </tr>
          </ng-template>
        </p-dataView>
      </p-tabPanel>
      <p-tabPanel *ngIf='registrationBinary.originalFilename' header='Fahrzeugschein'>
        <div class='containerVR'>
          <img class='custom-img' [src]='registrationBinary.preSignedS3UriThumbnail'
               alt='Fahrzeugschein' />
          <div class='img-button centered'>
            <button title='Bild in Galerie anzeigen'
                    (click)='showGalleria(registrationBinary.preSignedS3Uri)'
                    class='custom-tn-button mr-3'
                    pButton
                    icon='pi pi-search-plus'></button>
            <button title='Bild herunterladen'
                    (click)='downloadImage(registrationBinary.preSignedS3Uri, registrationBinary.originalFilename)'
                    class='custom-tn-button mr-3'
                    pButton
                    icon='pi pi-cloud-download'></button>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
<p-galleria [value]='binariesInclVR' [(visible)]='displayCustom' [(activeIndex)]='allActiveIndex'
            [circular]='true' [fullScreen]='true' [showItemNavigators]='true'
            [showThumbnails]='false' [baseZIndex]='100000'>
  <ng-template pTemplate='item' let-binary>
    <img [src]='binary.preSignedS3Uri' style='width: 80%; display: block;' [alt]='binary.originalFilename' />
  </ng-template>
  <ng-template pTemplate='thumbnail' let-binary>
    <div class='grid grid-nogutter justify-content-center'>
      <img [src]=binary.preSignedS3UriThumbnail style='display: block;' />
    </div>
  </ng-template>
</p-galleria>
<p-dialog [modal]='true' [closable]='true' header='Dokumente anfügen'
          [(visible)]='addDocumentVisible'>
  <p class='spacing'></p>
  <p-fileUpload *ngIf='!isOnUpload' (uploadHandler)='uploadHandler($event)'
                name='incidentBinaries[]'
                [customUpload]='true'
                [multiple]='true'
                accept='image/*' chooseLabel='Auswählen' uploadLabel='Hochladen' cancelLabel='Abbrechen'
                [maxFileSize]='10000000'>
    <ng-template pTemplate='content'>
      <ul *ngIf='uploadedFiles.length'>
        <li *ngFor='let file of uploadedFiles'>{{file.name}} - {{file.size}} bytes</li>
      </ul>
    </ng-template>
  </p-fileUpload>
  <span *ngIf='isOnUpload'>
			<p-progressBar [style]="{'height': '6px'}" mode='indeterminate'></p-progressBar>
	</span>
  <p class='spacing'></p>
</p-dialog>
<p-confirmDialog [breakpoints]="{'960px': '50vw', '640px': '80vw'}"
                 [style]="{width: '30vw'}"></p-confirmDialog>
<!-- syncAutoclaimDialog -->
<p-dialog [style]="{width: '25vw'}" [modal]='true' [closable]='false' header='Autoclaim Verknüpfung'
          [(visible)]='syncToAutoClaimPopup'>
  <form [formGroup]='incidentDetailViewForm'>
    <div class='grid mt-4'>
      <div class='col-12 p-fluid'>
									<span class='p-float-label p-input-icon-left'>
					<i class='pi pi-link'></i>
				<input formControlName='autoclaimReferenceNo'
               [value]="incident ? incident.autoclaimReferenceNo : ''"
               type='text' pInputText>
					<label>Autoclaim-Referenz-Nummer</label>
					</span>
      </div>
      <div class='col-12'>
        <button pButton class='float-end' label='Verknüpfen' (click)='linkAutoclaim()'></button>
        <button pButton class='float-end p-button-danger mr-2' label='Abbrechen'
                (click)='cancelLinkAutoclaim()'></button>
      </div>
    </div>
  </form>
</p-dialog>
