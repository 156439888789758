<div class='layout-wrapper'>
  <a *ngIf='checkPermissions()' (click)='onCurrentMeetingRoomButtonClick()' class='layout-config-button'
    style='cursor: pointer'>
    <i class='pi pi-video'></i>
  </a>
  <div class='layout-content-wrapper'>
    <div [ngClass]='{ hiddenNav: !menuActive }' class='layout-topbar'>
      <div class='topbar-left'>
        <a (click)='onMenuButtonClick()' class='menu-button'>
          <i [class]="menuActive ? 'pi pi-chevron-left' : 'pi pi-chevron-right'"></i>
        </a>
        <img alt='ploechinger-logo' class='mobile-logo' id='logo-mobile' src='assets/images/logo.png' />
      </div>
      <div [ngClass]='{ user: menuActive }' class='topbar-right'>
        <p-dropdown [options]='items' optionLabel='value'>
          <ng-template pTemplate='selectedItem'><i class='pi pi-user'></i> {{ currentUser.email }}
          </ng-template>
          <ng-template let-item pTemplate='item'>
            <div (click)='clickAction(item.id)'>{{ item.value }}</div>
          </ng-template>
        </p-dropdown>
      </div>
      <p-selectButton *ngIf='expertview' [(ngModel)]='permissionsService.selectedOption'
        [options]='permissionsService.viewOptions' optionLabel='label'></p-selectButton>
    </div>
    <p-sidebar [(visible)]='currentMeetingRoomSideBar' [baseZIndex]='10000' position='right'>
      <h1 *ngIf='rooms.length > 0' class='custom-header-peerSidebar' style='font-weight:normal'>Geöffnete
        Kalkulationen</h1>
      <span *ngIf='rooms.length > 0' class='mt-3'>
        <p-card *ngFor='let room of rooms; let elemIndex = index;' [style]='stylePeerCard' class='custom-space'>
          <h6 class='flex justify-content-center align-items-center'>Servicemitarbeiter</h6>
          <p *ngIf='room.employee_name' class='flex justify-content-center align-items-center'>{{room.employee_name}}
          </p>
          <p *ngIf='!room.employee_name' class='flex justify-content-center align-items-center'>Nicht verbunden</p>
          <div class='grid'>
            <div class='col-4 flex align-items-center justify-content-center'>
              <p *ngIf='room.employee_audio'><i class='pi pi-volume-up' style='font-size: 1.5rem; color: green'></i></p>
              <p *ngIf='!room.employee_audio'><i class='pi pi-volume-off' style='font-size: 1.5rem; color: red'></i></p>
            </div>
            <div class='col-4 flex align-items-center justify-content-center'>
              <p *ngIf='room.employee_video'><i class='pi pi-video' style='font-size: 1.5rem; color: green'></i></p>
              <p *ngIf='!room.employee_video'><i class='pi pi-video' style='font-size: 1.5rem; color: red'></i></p>
            </div>
            <div class='col-4 flex align-items-center justify-content-center'>
              <p *ngIf='!room.employee_time'><i class='pi pi-times-circle' style='font-size: 1.5rem; color: red'></i>
              </p>
              <p style='color: green'>{{room.employee_time}}</p>
            </div>
          </div>
          <hr>
          <h6 class='flex justify-content-center align-items-center'>ASM</h6>
          <p *ngIf='room.expert_name' class='flex justify-content-center align-items-center'>{{room.expert_name}}</p>
          <p *ngIf='!room.expert_name' class='flex justify-content-center align-items-center'>Nicht verbunden</p>
          <div class='grid'>
            <div class='col-4 flex align-items-center justify-content-center'>
              <p *ngIf='room.expert_audio'><i class='pi pi-volume-up' style='font-size: 1.5rem; color: green'></i></p>
              <p *ngIf='!room.expert_audio'><i class='pi pi-volume-off' style='font-size: 1.5rem; color: red'></i></p>
            </div>
            <div class='col-4 flex align-items-center justify-content-center'>
              <p *ngIf='room.expert_video'><i class='pi pi-video' style='font-size: 1.5rem; color: green'></i></p>
              <p *ngIf='!room.expert_video'><i class='pi pi-video' style='font-size: 1.5rem; color: red'></i></p>
            </div>
            <div class='col-4 flex align-items-center justify-content-center'>
              <p *ngIf='!room.expert_time'><i class='pi pi-times-circle' style='font-size: 1.5rem; color: red'></i></p>
              <p style='color: green'>{{room.expert_time}}</p>
            </div>
          </div>
        </p-card>
      </span>
      <span *ngIf='rooms.length === 0'>
        <h1 class='custom-header-peerSidebar' style='font-weight:normal'>Es gibt aktuell keine laufenden Kalkulationen.
        </h1>
      </span>
    </p-sidebar>
    <div (click)='onMaskClick()' [ngClass]="{ 'layout-mask-active': menuActive }" class='layout-mask'></div>
    <div [ngClass]='{ hiddenNav: !menuActive, calendar: !menuActive }' class='layout-content'>
      <router-outlet></router-outlet>
      <div class='spaceholder'></div>
      <app-footer></app-footer>
    </div>
  </div>
  <section class='container'>
    <aside>
      <app-sidebar [menuActive]='!menuActive'></app-sidebar>
    </aside>
  </section>
</div>