<div class='footer flex-row'>
    <div [ngClass]='sidebarState'>
        <div class='grid'>
            <div class='col'>
                <button class='feedbackButton' (click)='callSentryFeedbackForm()'>Feedback</button>
            </div>
            <div class='col'>
					<span>Smart-VDS.com ist ein Produkt der ZFT |
              <a href='https://zft.ploechinger.de/' target='_blank'>
            <img src='assets/images/zft-logo-2016.png' alt='bcx logo'/></a></span>
            </div>
        </div>
    </div>
</div>
