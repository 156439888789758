import { Component, OnInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { Room } from '../../models/room';
import { MeetingRoomService } from '../../service/meeting-room.service';
import * as Sentry from '@sentry/angular';
import { WaitingRoomModel } from '../../models/waitingRoomModel';
import { IncidentService } from '../../incident/incident.service';

@Component({
	selector: 'app-waiting-room',
	templateUrl: './waiting-room.component.html',
	styleUrls: ['./waiting-room.component.css'],
})
export class WaitingRoomComponent implements OnInit {
	public rooms: WaitingRoomModel[] = [];
	public now: Date = new Date();

	constructor(private meetingRoomService: MeetingRoomService, private changeDetector: ChangeDetectorRef, private incidentService: IncidentService) {
		setInterval(() => {
			this.now = new Date();
		}, 1000);

		setInterval(async () => {
			this.rooms = await this.getIncompleteMeetingRoom();

			for (let i = 0; i < this.rooms.length; i++) {
				if (typeof this.rooms[i].incident === 'undefined') {
					this.rooms[i].incident = await this.incidentService.get(this.rooms[i].room_parameter);
				}
			}
		}, 10000);
	}

	getEnumName(type: string) {
		if (type) {
			if (type === 'Liability') {
				return 'Haftpflicht';
			} else if (type === 'PartiallyComprehensive') {
				return 'Teilkasko';
			} else if (type === 'FullyComprehensive') {
				return 'Vollkasko';
			} else if (type === 'SelfPayer') {
				return 'Selbstzahler';
			} else {
				return 'Nicht gesetzt';
			}
		} else {
			return 'Nicht gesetzt';
		}
	}

	async ngOnInit() {
		this.rooms = await this.getIncompleteMeetingRoom();
	}

	ngAfterContentChecked(): void {
		this.changeDetector.detectChanges();
	}

	callSentryFeedbackForm() {
		var error = new Error('Call feedback form');
		Sentry.captureException(error);
		Sentry.showReportDialog();
	}

	getWaitingTime(dateTime: string): string {
		if (dateTime) {
			let hours = dateTime.charAt(0);
			hours = hours + dateTime.charAt(1);

			let minutes = dateTime.charAt(3);
			minutes = minutes + dateTime.charAt(4);

			if (Number(hours) < 1) {
				if (Number(minutes) < 10) {
					return 'waiting-time-tr-yellow';
				} else {
					return 'waiting-time-tr-red';
				}
			} else {
				return 'waiting-time-tr-red';
			}
		} else {
			console.error('datetime not set');
			return '';
		}
	}

	async getIncompleteMeetingRoom(): Promise<WaitingRoomModel[]> {
		let meetingRooms = await this.meetingRoomService.getCurrentOpenMeetingRoom();
		return meetingRooms.filter((mr: { expert_name: string }) => mr.expert_name === undefined);
	}
}
