import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ActivateComponent } from './auth/activate/activate.component';
import { AuthGuard } from './auth/guards/auth.guard';
import { WaitingRoomComponent } from './shared/components/waiting-room/waiting-room.component';

const routes: Routes = [
	{
		path: '',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'dashboard',
				redirectTo: 'dashboard',
				pathMatch: 'full',
			},
			{
				path: 'dashboard',
				canActivate: [AuthGuard],
				loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
			},
		],
	},
	{
		path: 'waiting-room',
		canActivate: [AuthGuard],
		component: WaitingRoomComponent,
	},
	{
		path: 'activate',
		component: ActivateComponent,
		children: [
			{
				path: 'activate',
				loadChildren: () => import('./auth/activate/activate.module').then((m) => m.ActivateModule),
			},
		],
	},
	{
		path: '',
		component: LoginComponent,
		children: [
			{
				path: 'login',
				loadChildren: () => import('./auth/login/login.module').then((m) => m.LoginModule),
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
